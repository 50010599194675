import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateGroupSettingAttendanceRecordTypeRequest,
  QueryGroupSettingAttendanceRecordTypesByFilterRequest,
  QueryGroupSettingAttendanceRecordTypesByFilterResponse,
} from '@/lib/groupSetting/interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const groupSettingApi = createApi({
  reducerPath: 'groupSettingApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    queryGroupSettingAttendanceRecordTypesByFilter: builder.query<
      QueryGroupSettingAttendanceRecordTypesByFilterResponse[],
      QueryGroupSettingAttendanceRecordTypesByFilterRequest
    >({
      query: (params) => ({
        url: `/api/GroupSetting/QueryGroupSettingAttendanceRecordTypesByFilter`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
    }),
    createGroupSettingAttendanceRecordType: builder.mutation<
      any,
      CreateGroupSettingAttendanceRecordTypeRequest
    >({
      query: (data) => ({
        url: `/api/GroupSetting/CreateGroupSettingAttendanceRecordType`,
        method: 'POST',
        data,
      }),
    }),
  }),
});

export const {
  useQueryGroupSettingAttendanceRecordTypesByFilterQuery,
  useCreateGroupSettingAttendanceRecordTypeMutation,
} = groupSettingApi;
