import { createSlice } from '@reduxjs/toolkit';
import { equipmentApi } from './equipmentAPI';
import {
  EquipmentLocationRecord,
  EquipmentLocationRecordData,
} from './interface';

interface EquipmentState {
  equipmentFlexPane: boolean;
  equipmentId: number;
  selectedRecord: {
    equipmentAttendanceRecordId: number;
    projectName: string;
    checkInLatitude: number;
    checkInLongitude: number;
    checkOutLatitude: number | null;
    checkOutLongitude: number | null;
  } | null;
  equipmentLocationRecords: EquipmentLocationRecord;
}

const initialState: EquipmentState = {
  equipmentFlexPane: false,
  equipmentId: 0,
  selectedRecord: null,
  equipmentLocationRecords: {
    recordPageData: {
      data: [],
      currentPageCount: 0,
      pageNumber: 0,
      pageCount: 0,
      totalCount: 0,
      totalPageCount: 0,
    },
    users: [],
  },
};

const equipmentSlice = createSlice({
  name: 'equipment',
  initialState,
  reducers: {
    openEquipmentFlexPane: (state) => {
      state.equipmentFlexPane = true;
    },
    closeEquipmentFlexPane: (state) => {
      state.equipmentFlexPane = false;
    },
    setEquipmentId: (state, action) => {
      state.equipmentId = action.payload;
    },
    setSelectedRecord: (state, action) => {
      state.selectedRecord = {
        equipmentAttendanceRecordId: action.payload.equipmentAttendanceRecordId,
        projectName: action.payload.projectName,
        checkInLatitude: action.payload.checkInLatitude,
        checkInLongitude: action.payload.checkInLongitude,
        checkOutLatitude: action.payload.checkOutLatitude,
        checkOutLongitude: action.payload.checkOutLongitude,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      equipmentApi.endpoints.queryEquipmentLocationRecordByPageFilter
        .matchFulfilled,
      (state, action) => {
        const existingEquipmentRecordIds = new Set(
          state.equipmentLocationRecords.recordPageData.data.map(
            (record) => record.equipmentAttendanceRecordId,
          ),
        );
        const updateData = action.payload.recordPageData.data
          .map((newRecord) => {
            const existingRecordIndex =
              state.equipmentLocationRecords.recordPageData.data.findIndex(
                (record) =>
                  record.equipmentAttendanceRecordId ===
                  newRecord.equipmentAttendanceRecordId,
              );
            if (existingRecordIndex !== -1) {
              state.equipmentLocationRecords.recordPageData.data[
                existingRecordIndex
              ] = newRecord;
              return null;
            }
            return newRecord;
          })
          .filter(
            (record): record is EquipmentLocationRecordData => record !== null,
          );
        const mergedData = [
          ...updateData,
          ...state.equipmentLocationRecords.recordPageData.data,
        ].sort(
          (a, b) =>
            (b.equipmentAttendanceRecordId ?? 0) -
            (a.equipmentAttendanceRecordId ?? 0),
        );

        // 合併users並過濾重複
        const mergedUsers = Array.from(
          new Set([
            ...state.equipmentLocationRecords.users,
            ...action.payload.users.filter(
              (user) =>
                !state.equipmentLocationRecords.users.some(
                  (existingUser) => existingUser.userId === user.userId,
                ),
            ),
          ]),
        );

        state.equipmentLocationRecords = {
          ...action.payload,
          recordPageData: {
            ...action.payload.recordPageData,
            currentPageCount: action.payload.recordPageData.currentPageCount,
            pageCount: action.payload.recordPageData.pageCount,
            totalCount: action.payload.recordPageData.totalCount,
            totalPageCount: action.payload.recordPageData.totalPageCount,
            data: mergedData,
          },
          users: mergedUsers,
        };
      },
    );
  },
});

export const {
  openEquipmentFlexPane,
  closeEquipmentFlexPane,
  setEquipmentId,
  setSelectedRecord,
} = equipmentSlice.actions;
export default equipmentSlice.reducer;
