import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';
import { memberApi } from '../member/memberAPI';
import {
  CreateAttendanceRecordRequest,
  DeleteAttendanceRecordRequest,
  GetAttendanceRecordDetailRequest,
  GetAttendanceRecordDetailResponse,
  GetAttendanceRecordRequestDetailRequest,
  GetAttendanceRecordRequestDetailResponse,
  QueryTimeSheetManagerSummaryRequest,
  QueryTimeSheetManagerSummaryResponse,
  QueryUserAttendanceRecordsByFilterReturnViewModelRequest,
  QueryUserAttendanceRecordsByFilterReturnViewModelResponse,
  UpdateAttendanceRecordRequest,
} from './interface';

export const timesheetApi = createApi({
  reducerPath: 'timesheetApi',
  tagTypes: ['record', 'timeSheetManagerSummary', 'recordDetail'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    updateAttendanceRecord: builder.mutation<
      any,
      UpdateAttendanceRecordRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/UpdateAttendanceRecord`,
        method: 'PUT',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(memberApi.util.invalidateTags(['manpowerRecord']));
        } catch (error) {
          throw new Error(error.message);
        }
      },
      invalidatesTags: ['record', 'recordDetail'],
    }),
    deleteAttendanceRecord: builder.mutation<
      any,
      DeleteAttendanceRecordRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/DeleteAttendanceRecord`,
        method: 'DELETE',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(memberApi.util.invalidateTags(['manpowerRecord']));
        } catch (error) {
          throw new Error(error.message);
        }
      },
      invalidatesTags: ['record', 'recordDetail'],
    }),
    queryTimeSheetManagerSummaryWithPage: builder.query<
      QueryTimeSheetManagerSummaryResponse,
      QueryTimeSheetManagerSummaryRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/QueryTimeSheetManagerSummaryWithPage`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.GroupId}_${queryArgs.PageNumber}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.PageNumber !== previousArg?.PageNumber ||
          currentArg?.GroupId !== previousArg?.GroupId ||
          currentArg?.StartDate !== previousArg?.StartDate ||
          currentArg?.EndDate !== previousArg?.EndDate
        );
      },
    }),
    queryUserAttendanceRecordsByFilterReturnViewModel: builder.query<
      QueryUserAttendanceRecordsByFilterReturnViewModelResponse,
      QueryUserAttendanceRecordsByFilterReturnViewModelRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/QueryUserAttendanceRecordsByFilterReturnViewModel`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.GroupId}-${queryArgs.UserId}-${queryArgs.StartDate}-${queryArgs.EndDate}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.UserId !== previousArg?.UserId ||
          currentArg?.GroupId !== previousArg?.GroupId ||
          currentArg?.StartDate !== previousArg?.StartDate ||
          currentArg?.EndDate !== previousArg?.EndDate ||
          currentArg?.HasIssues !== previousArg?.HasIssues
        );
      },
      providesTags: ['record'],
    }),
    getAttendanceRecordDetail: builder.query<
      GetAttendanceRecordDetailResponse,
      GetAttendanceRecordDetailRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/GetAttendanceRecordDetail`,
        method: 'GET',
        params: data,
      }),
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.AttendanceRecordId !== previousArg?.AttendanceRecordId
        );
      },
      transformResponse: (response) => response.data.data,
      providesTags: ['recordDetail'],
    }),
    createAttendanceRecord: builder.mutation<
      any,
      CreateAttendanceRecordRequest
    >({
      query: (data) => ({
        url: `/api/TimeSheet/CreateAttendanceRecord`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['record', 'recordDetail'],
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(memberApi.util.invalidateTags(['manpowerRecord']));
      },
    }),
    getAttendanceRecordRequestDetail: builder.query<
      GetAttendanceRecordRequestDetailResponse,
      GetAttendanceRecordRequestDetailRequest
    >({
      query: (params) => ({
        url: `/api/TimeSheet/GetAttendanceRecordRequestDetail`,
        method: 'GET',
        params,
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.attendanceRecordRequestId !==
          previousArg?.attendanceRecordRequestId
        );
      },
      providesTags: ['recordDetail'],
    }),
  }),
});

export const {
  useUpdateAttendanceRecordMutation,
  useDeleteAttendanceRecordMutation,
  useQueryTimeSheetManagerSummaryWithPageQuery,
  useQueryUserAttendanceRecordsByFilterReturnViewModelQuery,
  useCreateAttendanceRecordMutation,
  useGetAttendanceRecordDetailQuery,
  useGetAttendanceRecordRequestDetailQuery,
} = timesheetApi;
