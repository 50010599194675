import { createTheme } from '@mui/material/styles';
import { Poppins } from 'next/font/google';

const poppins = Poppins({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
});

const theme = createTheme({
  components: {
    // 元件的名稱
    MuiButton: {
      styleOverrides: {
        // 元件的 slot
        root: {
          textTransform: 'none'
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#0029A5',
    },
  },
  background: { paper: '#0029A5', default: '#0029A5' },
  typography: {
    fontFamily: poppins,
  },
  
  // components: {
  //   MuiButton: {
  //     styleOverrides: {
  //       root: {
  //         textTransform: 'none',
  //         fontWeight: '500',
  //         backgroundColor: '#0029A5'
  //       },
  //     },
  //   },
  // },
});

export default theme;
