import { ApiPhoto } from '@/types/interface';
import request from '@/utils/request';
import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';

type NewsfeedDetail = {
  projectNewsfeedId: number;
  projectId: number;
  projectName: string;
  projectColor: string;
  activityId: number;
  activityName: string;
  date: Date;
  newsTemplateId: number;
  photoCount: number;
  createdUserName: string;
  photos: ApiPhoto[];
};

interface PagedParams {
  groupId: number;
  pageNumber: number;
  pageCount: number;
}

export const newsfeedApi = createApi({
  reducerPath: 'newsfeedApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['Newsfeed'],
  endpoints: (builder) => ({
    queryNewsfeedAndWeeklyReportWithPaged: builder.mutation<any, PagedParams>({
      query: ({ groupId, pageNumber, pageCount }) => ({
        url: `/api/user/queryNewsfeedAndWeeklyReportWithPaged`,
        method: 'GET',
        params: {
          groupId,
          pageNumber,
          pageCount,
        },
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    GetNewsfeedDetail: builder.query<any, any>({
      query: (newsfeedId) => ({
        url: `/api/user/GetNewsfeedDetail`,
        method: 'GET',
        params: {
          newsfeedId,
        },
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
  }),
});

export const {
  useQueryNewsfeedAndWeeklyReportWithPagedMutation,
  useGetNewsfeedDetailQuery,
} = newsfeedApi;
