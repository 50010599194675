import request from '@/utils/request';

const URL = '/api/AdminConsole';

export function getCompanyOverView(groupId: number) {
  return request({
    url: `${URL}/GetCompanyOverview?GroupId=${groupId}`,
  });
}

export function getCompanies() {
  return request({
    url: `${URL}/GetCompanies`,
  });
}

export function renameAccountNameAsync(data) {
  return request({
    url: `${URL}/RenameAccountName`,
    method: 'POST',
    data,
  });
}
