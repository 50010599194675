import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { format, startOfMonth } from 'date-fns';

interface InitialStateProps {
  recordFlexPane: boolean;
  startDate: string;
  endDate: string;
  selectedAttendanceRecordId: number | null;
  selectedAttendanceRecordDate: {
    label: string;
    value: string;
  } | null;
  selectedRequestStatus: 'pending' | 'history';
  initialDateRange: {
    start: string;
    end: string;
  };
  isApprove: boolean;
  repliedAttendanceRecordRequestIds: number[];
}

const initialState: InitialStateProps = {
  recordFlexPane: false,
  startDate: format(new Date(), 'yyyy-MM-dd'),
  endDate: format(new Date(), 'yyyy-MM-dd'),
  selectedAttendanceRecordId: 0,
  selectedAttendanceRecordDate: null,
  selectedRequestStatus: 'pending',
  initialDateRange: {
    start: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
    end: format(new Date(), 'yyyy-MM-dd'),
  },
  isApprove: false,
  repliedAttendanceRecordRequestIds: [],
};

const timesheetSlice = createSlice({
  name: 'timesheet',
  initialState,
  reducers: {
    openRecordFlexPane: (state) => {
      state.recordFlexPane = true;
    },
    closeRecordFlexPane: (state) => {
      state.recordFlexPane = false;
    },
    setStartDate: (state, action) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.startDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid start date:', action.payload);
        state.startDate = format(new Date(), 'yyyy-MM-dd');
      }
    },
    setEndDate: (state, action) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.endDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid end date:', action.payload);
        state.endDate = format(new Date(), 'yyyy-MM-dd');
      }
    },
    setSelectedAttendanceRecordId: (state, action) => {
      state.selectedAttendanceRecordId = action.payload;
    },
    setSelectedAttendanceRecordDate: (state, action) => {
      state.selectedAttendanceRecordDate = action.payload;
    },
    setSelectedRequestStatus: (state, action) => {
      state.selectedRequestStatus = action.payload;
    },
    setInitialDateRange: (state, action) => {
      state.initialDateRange = action.payload;
    },
    setRangeStartDate: (state, action: PayloadAction<string>) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.initialDateRange.start = format(date, 'yyyy-MM-dd');
        state.startDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid range start date:', action.payload);
      }
    },
    setRangeEndDate: (state, action: PayloadAction<string>) => {
      try {
        const date = new Date(action.payload);
        if (isNaN(date.getTime())) {
          throw new Error('Invalid date');
        }
        state.initialDateRange.end = format(date, 'yyyy-MM-dd');
        state.endDate = format(date, 'yyyy-MM-dd');
      } catch (error) {
        console.error('Invalid range end date:', action.payload);
      }
    },
    setIsApprove: (state, action) => {
      state.isApprove = action.payload;
    },
    setRepliedAttendanceRecordRequestIds: (state, action) => {
      state.repliedAttendanceRecordRequestIds = action.payload;
    },
  },
});

export const {
  openRecordFlexPane,
  closeRecordFlexPane,
  setStartDate,
  setEndDate,
  setSelectedAttendanceRecordId,
  setSelectedAttendanceRecordDate,
  setSelectedRequestStatus,
  setInitialDateRange,
  setRangeStartDate,
  setRangeEndDate,
  setIsApprove,
  setRepliedAttendanceRecordRequestIds,
} = timesheetSlice.actions;
export default timesheetSlice.reducer;
