import { createSlice } from '@reduxjs/toolkit';

interface initialState {
  selectedMember: number | null;
  selectedMemberEmail: string;
  selectedMemberProject: number;
  memberDetailFlexPane: boolean;
  teamDetailFlexPane: boolean;
  selectedRequestRecordId: number | null;
}

const initialState: initialState = {
  selectedMember: null,
  selectedMemberEmail: '',
  selectedMemberProject: 0,
  memberDetailFlexPane: false,
  teamDetailFlexPane: false,
  selectedRequestRecordId: null,
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setSelectedMember: (state, action) => {
      state.selectedMember = action.payload;
    },
    setSelectedMemberEmail: (state, action) => {
      state.selectedMemberEmail = action.payload;
    },
    setSelectedMemberProject: (state, action) => {
      state.selectedMemberProject = action.payload;
    },
    setMemberDetailFlexPane: (state, action) => {
      state.memberDetailFlexPane = action.payload;
    },
    setTeamDetailFlexPane: (state, action) => {
      state.teamDetailFlexPane = action.payload;
    },
    setSelectedRequestRecordId: (state, action) => {
      state.selectedRequestRecordId = action.payload;
    },
  },
});

export const {
  setSelectedMember,
  setSelectedMemberEmail,
  setSelectedMemberProject,
  setMemberDetailFlexPane,
  setTeamDetailFlexPane,
  setSelectedRequestRecordId,
} = memberSlice.actions;
export default memberSlice.reducer;
