import perAmount from '@/utils/perAmount';
import { createSlice } from '@reduxjs/toolkit';
import { fetchGetCompanyOverViewThunk } from './accountAction';

interface InitialState {
  companyOverview: {
    value: any;
    initialSeats: number;
    initialAmount: number;
    initialPlan: string;
    selectPlanType: number;
    selectPaymentType: number;
    error: any
  };
  companies: {
    data: any[];
  };
  rename: string;
}

const initialState:InitialState = {
  companyOverview: {
    value: {},
    initialSeats: 1,
    initialAmount: 0,
    initialPlan: 'Free',
    selectPlanType: 1, // 這是付費等級
    selectPaymentType: 1, // 這是付費週期
    error:{}
  },
  companies: {
    data: [],
  },
  rename: '',
};

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    increment: (state) => {
      state.companyOverview.value.seatsCount = Math.min(
        state.companyOverview.value.seatsCount + 1,
        100,
      );
    },
    decrement: (state) => {
      state.companyOverview.value.seatsCount = Math.max(
        state.companyOverview.value.seatsCount - 1,
        state.companyOverview.initialSeats,
      );
    },
    setSeatCount: (state, action) => {
      state.companyOverview.value.seatsCount = action.payload;
    },
    resetSeats: (state) => {
      state.companyOverview.value.seatsCount =
        state.companyOverview.initialSeats;
    },
    setPlanType: (state, action) => {
      state.companyOverview.selectPlanType = action.payload;
    },
    setPaymentType: (state, action) => {
      state.companyOverview.selectPaymentType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGetCompanyOverViewThunk.fulfilled, (state, action) => {
        state.companyOverview.value = action?.payload;
        state.companyOverview.initialSeats = action.payload.seatsCount;
        state.companyOverview.initialAmount = perAmount(
          action.payload.planType,
          action.payload.paymentType,
        );
        state.companyOverview.initialPlan = action.payload.planTypeName;
      })
      .addCase(fetchGetCompanyOverViewThunk.rejected, (state, action) => {
        state.companyOverview.error = action.error;
      });
  },
});

export const { increment, decrement, resetSeats, setPlanType, setPaymentType, setSeatCount } =
  accountSlice.actions;
export default accountSlice.reducer;
