'use client';

import { Provider } from 'react-redux';

// eslint-disable-next-line import/named
import store from './store';

// 載入初始數據
// import { initializeAuth} from "./features/auth/authSlice"

export default function Providers({ children }) {
  return <Provider store={store}>{children}</Provider>;
}

// 檢查引用的值來確保該客戶端元件重新呈現安全，以確保儲存僅建立一次。
// 對於伺服器上的每個請求，此元件只會呈現一次，但如果樹中此元件上方有有狀態用戶端元件，
// 或者此元件還包含其他導致錯誤的可變狀態，則可能會在用戶端上重新呈現多次。重新渲染。
