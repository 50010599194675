import request from '@/utils/request';

export function GetMembersOverView(id) {
  return request({
    url: `/api/AdminConsole/GetMembersOverView?GroupId=${id}`,
  });
}

export function RemoveMember(data) {
  return request({
    url: `/api/AdminConsole/RemoveMember`,
    method: 'POST',
    data,
  });
}

export function ModifyMemberAsMember(data) {
  return request({
    url: `/api/AdminConsole/ModifyMemberAsMember`,
    method: 'POST',
    data,
  });
}

export function ModifyMemberAsCoOwner(data) {
  return request({
    url: `/api/AdminConsole/ModifyMemberAsCoOwner`,
    method: 'POST',
    data,
  });
}
