import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosResponse } from 'axios';
import { ApiResponse } from '@/types/interface';
import { axiosBaseQuery } from '../axiosBaseQuery';
import {
  AttendanceRecords,
  ClockAttendanceRecordDetail,
  RecordTypes,
} from './interface';

export const clockApi = createApi({
  reducerPath: 'clockAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getAttendanceRecordTypes: builder.query<RecordTypes[], any>({
      query: () => ({
        url: `/api/Clock/GetAttendanceRecordTypes`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    getAttendanceRecordCheckTypes: builder.query<any, any>({
      query: () => ({
        url: `/api/Clock/GetAttendanceRecordCheckTypes`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    getAttendanceRecordManagerActionTypes: builder.query<any, any>({
      query: () => ({
        url: `/api/Clock/GetAttendanceRecordManagerActionTypes`,
        method: 'GET',
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    GetSelfTodayLatestAttendanceRecordBrief: builder.query<any, number>({
      query: (groupId) => ({
        url: `/api/Clock/GetSelfTodayLatestAttendanceRecordBrief`,
        method: 'GET',
        params: { groupId },
      }),
      transformResponse: (response: any) => {
        return response.data.data;
      },
    }),
    GetAttendanceRecordDetail: builder.query<
      ClockAttendanceRecordDetail,
      number
    >({
      query: (attendanceRecordId) => ({
        url: `/api/Clock/GetAttendanceRecordDetail`,
        method: 'GET',
        params: { attendanceRecordId },
      }),
      transformResponse: (
        response: AxiosResponse<ApiResponse<ClockAttendanceRecordDetail>>,
      ) => {
        return response.data.data;
      },
    }),
    getQuerySelfAttendanceRecordsByFilterReturnViewModel: builder.query<
      AttendanceRecords,
      any
    >({
      query: (data) => ({
        url: `/api/Clock/QuerySelfAttendanceRecordsByFilterReturnViewModel`,
        method: 'POST',
        data,
      }),

      transformResponse: (response: any) => {
        return response.data.data;
      },
      forceRefetch: () => true,
    }),
  }),
});

export const {
  useGetAttendanceRecordTypesQuery,
  useGetAttendanceRecordCheckTypesQuery,
  useGetAttendanceRecordManagerActionTypesQuery,
  useGetSelfTodayLatestAttendanceRecordBriefQuery,
  useGetAttendanceRecordDetailQuery,
  useGetQuerySelfAttendanceRecordsByFilterReturnViewModelQuery,
} = clockApi;
