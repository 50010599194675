import request from '@/utils/request'

const URL = '/api/Payment'

const AdminURL = '/api/AdminConsole'

export function getPaymentTypes () {
  return request({
    url: `${URL}/GetPaymentTypes`,
    method: 'GET'
  })
}

export function getPlanTypes () {
  return request({
    url: `${URL}/GetPlanTypes`,
    method: 'GET'
  })
}

export function getGroupReceiptsByPageFilter (GroupId, PageNumber, PageCount) {
  return request({
    url: `${AdminURL}/QueryGroupReceiptsByPageFilter?GroupId=${GroupId}&PageNumber=${PageNumber}&PageCount=${PageCount}`,
    method: 'GET'
  })
}

export function postPreviewCheckout (data) {
  return request({
    url: `${URL}/PreviewCheckOut`,
    method: 'POST',
    data
  })
}

export function postCheckout (data) {
  return request({
    url: `${URL}/Checkout`,
    method: 'POST',
    data
  })
}

export function postGetUpdatePaymentInfo(groupId) {
  return request({
    url: `${URL}/GetUpdatePaymentInfo`,
    method: 'POST',
    data : {
      GroupId: groupId,
    }
  })
}

export function postUpdatePaymentEmail(groupId, email) {
  return request({
    url: `${AdminURL}/UpdatePaymentEmail`,
    method: 'POST',
    data: {
      GroupId: groupId,
      PaymentEmail: email
    }
  })
}

export function postCancelSubscription (groupId) {
  return request({
    url: `${URL}/CancelSubscription`,
    method: 'POST',
    data : {
      GroupId: groupId
    }
  })
}

export function getInvoiceDownloadUrlAsync(groupReceiptId) {
  return request({
    url: `${URL}/GetInvoiceDownloadUrlAsync?GroupReceiptId=${groupReceiptId}`,
    method: 'GET',
  });
}

export function postPreUpdateSeats (data) {
  return request({
    url: `${URL}/PreUpdateSeats`,
    method: 'POST',
    data
  })
}

export function postUpdateSeats (data) {
  return request({
    url: `${URL}/UpdateSeats`,
    method: 'POST',
    data
  })
}

export function postPreUpdateNextPlan (data) {
  return request({
    url: `${URL}/PreUpdateNextPlan`,
    method: 'POST',
    data
  })
}

export function postUpdateNextPlan (data) {
  return request({
    url: `${URL}/UpdateNextPlan`,
    method: 'POST',
    data
  })
}

export function postResumeNextPlan (data) {
  return request({
    url: `${URL}/ResumeNextPlan`,
    method: 'POST',
    data
  })
}