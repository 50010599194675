import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AssignToMultipleUsersAndProjects,
  AssignToUserProject,
  CreateTeams,
  GetTeams,
  GetTeamsByPageFilter,
  GetTeamsByPageFilterRequest,
  RemoveTeamFromUserProject,
  UpdateTeams,
} from '@/lib/teams/interface';
import { projectsApi } from '@/lib/projects/projectsAPI';

import { axiosBaseQuery } from '../axiosBaseQuery';

export const teamsApi = createApi({
  reducerPath: 'teamsApi',
  tagTypes: ['teams'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getTeams: builder.query<GetTeams[], void>({
      query: () => ({
        url: `/api/Teams`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['teams'],
    }),
    createTeams: builder.mutation<any, CreateTeams>({
      query: (data) => ({
        url: `/api/Teams`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['teams'],
    }),
    getTeamDetail: builder.query<GetTeams, number>({
      query: (id) => ({
        url: `/api/Teams/${id}`,
        method: 'GET',
      }),
      transformResponse: (response) => response.data.data,
      providesTags: ['teams'],
    }),
    updateTeam: builder.mutation<any, UpdateTeams>({
      query: (data) => ({
        url: `/api/Teams/${data.teamId}`,
        method: 'PUT',
        data: {
          name: data.name,
          note: data.note,
        },
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(projectsApi.util.invalidateTags(['MemberProjects']));
        } catch (error) {
          throw new Error(error.message);
        }
      },
      invalidatesTags: ['teams'],
    }),
    deleteTeam: builder.mutation<any, number>({
      query: (id) => ({
        url: `/api/Teams/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['teams'],
    }),
    assignToUserProject: builder.mutation<any, AssignToUserProject>({
      query: (data) => ({
        url: `/api/Teams/AssignToUserProject`,
        method: 'POST',
        data,
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        try {
          dispatch(projectsApi.util.invalidateTags(['MemberProjects']));
        } catch (error) {
          throw new Error(error.message);
        }
      },
    }),
    assignToMultipleUsersAndProjects: builder.mutation<
      any,
      AssignToMultipleUsersAndProjects
    >({
      query: (data) => ({
        url: `/api/Teams/AssignToMultipleUsersAndProjects`,
        method: 'POST',
        data,
      }),
    }),
    removeTeamFromUserProject: builder.mutation<any, RemoveTeamFromUserProject>(
      {
        query: (data) => ({
          url: `/api/Teams/RemoveTeamFromUserProject`,
          method: 'DELETE',
          data,
        }),
        async onQueryStarted(arg, { dispatch, queryFulfilled }) {
          await queryFulfilled;
          try {
            dispatch(projectsApi.util.invalidateTags(['MemberProjects']));
          } catch (error) {
            throw new Error(error.message);
          }
        },
      },
    ),
    getTeamsByPageFilter: builder.query<
      GetTeamsByPageFilter,
      GetTeamsByPageFilterRequest
    >({
      query: (params) => ({
        url: `/api/Teams/GetTeamsByPageFilter`,
        method: 'GET',
        params,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        return `${endpointName}-${queryArgs.groupId}-${queryArgs.pageNumber}`;
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        return (
          currentArg?.pageNumber !== previousArg?.pageNumber ||
          currentArg?.groupId !== previousArg?.groupId ||
          currentArg?.keyword !== previousArg?.keyword
        );
      },
      transformResponse: (response) => response.data.data,
      providesTags: ['teams'],
    }),
  }),
});

export const {
  useGetTeamsQuery,
  useCreateTeamsMutation,
  useGetTeamDetailQuery,
  useUpdateTeamMutation,
  useDeleteTeamMutation,
  useAssignToUserProjectMutation,
  useAssignToMultipleUsersAndProjectsMutation,
  useRemoveTeamFromUserProjectMutation,
  useGetTeamsByPageFilterQuery,
} = teamsApi;
