import { createSlice } from '@reduxjs/toolkit';
import { GetMembersOverViewThunk } from './memberAction';

const initialState = {
  members: {
    membersCount: 0,
    pendingInviteCount: 0,
    accountOwnersCount: 0,
    members: [],
    projects: [],
  },
};

const memberSlice = createSlice({
  name: 'member',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(GetMembersOverViewThunk.fulfilled, (state, action) => {
        state.members.membersCount = action?.payload?.membersCount;
        state.members.pendingInviteCount = action?.payload?.pendingInviteCount;
        state.members.accountOwnersCount = action?.payload?.accountOwnersCount;
        state.members.members = action?.payload?.members;
        state.members.projects = action?.payload?.projects;
      })
      .addCase(GetMembersOverViewThunk.rejected, (state, action) => {
        state.members = 'error';
        state.members = action.error;
      });
  },
});

export const { getMembersCount } = memberSlice.actions;
export default memberSlice.reducer;
