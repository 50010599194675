import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeamState {
  teamId: number | null;
}

const initialState: TeamState = {
  teamId: null,
};

const teamsSlice = createSlice({
  name: 'teams',
  initialState,
  reducers: {
    setTeamId: (state, action: PayloadAction<number | null>) => {
      state.teamId = action.payload;
    },
  },
});

export const { setTeamId } = teamsSlice.actions;
export default teamsSlice.reducer;
