import { createApi } from '@reduxjs/toolkit/query/react';
import { format, parseISO } from 'date-fns';
import { Option } from '@/components/base/BaseMenu/BaseMenu';
import { PreviewPhotos } from './interface';
import { axiosBaseQuery } from '../axiosBaseQuery';

export interface UpdateActivity {
  activityId: number;
  name: string;
  startDate: string;
  endDate: string;
  totalQty: number;
  activityUnitId: number;
}
export interface CreateActivity {
  projectId: number;
  name: string;
  startDate: string;
  endDate: string;
  totalQty: number;
  activityUnitId: number;
}

export interface ActivityId {
  activityId: number;
}

export interface ActivityRecordId {
  activityRecordId: number;
}

export interface QueryActivityUnits {
  groupId: number;
  createdBy: number;
}

export interface CreateActivityUnit {
  name: string;
  groupId: number;
}

export interface UpdateActivityUnit {
  name: string;
  activityUnitId: number;
}

export interface DeleteActivityUnit {
  activityUnitId: number;
}

export interface DeleteActivityRecordParams {
  activityRecordId: number;
}

export interface DeleteActivityRecordPhotoParams {
  projectId: number;
  activityId: number;
  activityRecordId: number;
  photoId: number;
}

export interface ActivityProductivityParameters {
  activityId: number;
  type: number;
  year: number;
  TimeZone?: string;
  startDate: string;
  endDate: string;
  createdBy: number;
}

export interface QueryActivityByPageFilterParams {
  projectId?: number;
  pageCount?: number;
  pageNumber?: number;
  isArchived?: boolean;
  keyword?: string;
}

export const activityAPI = createApi({
  reducerPath: 'activityAPI',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'Activity',
    'ActivityRecord',
    'ActivityUnit',
    'ActivityComments',
    'ActivityRecordPhoto',
    'ActivityPhotosAsync',
    'ActivityRecords',
  ],
  endpoints: (builder) => ({
    getQueryActivityOptionsByFilter: builder.query<Option[], any>({
      query: (data) => ({
        url: `/api/activity/QueryActivityOptionsByFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any) => {
        const optionData = response.data.data.map((e) => ({
          label: e.name ? e.name : `(${e.activityId})`,
          value: e.currentQtyPercentage,
          id: e.activityId,
        }));
        return optionData;
      },
    }),
    getQueryActivityPreviewPhotosByFilter: builder.query<PreviewPhotos[], any>({
      query: (data) => ({
        url: `/api/activity/QueryActivityPreviewPhotosByFilter`,
        method: 'POST',
        data,
      }),
      transformResponse: (response: any, _: any, arg: any) => {
        // 目前 API 沒有過濾功能，先在這邊過濾
        const { projectIds, period, pagination, activityIds } = arg;
        const filteredData = response.data.data.filter((item: any) =>
          activityIds.includes(item.activityId),
        );

        return filteredData;
      },
    }),
    getQueryLatestActivityPhotoByFilter: builder.query<any, any>({
      query: (data) => ({
        url: `/api/activity/QueryLatestActivityPhotoByFilter`,
        method: 'GET',
        params: data,
      }),
      transformResponse: (response: any) => {
        // 按照日期分組
        const groupedPhotos = response.data.data.reduce((acc, photo) => {
          const date = format(parseISO(photo.created), 'yyyy-MM-dd');
          if (!acc[date]) {
            acc[date] = {
              date,
              photos: [],
            };
          }
          acc[date].photos.push(photo);
          return acc;
        }, {});

        // 把結果轉成 Array
        const result = Object.values(groupedPhotos);
        return result;
      },
    }),
    QueryLatestActivityPhotoByPageFilter: builder.query<
      any,
      QueryActivityByPageFilterParams
    >({
      query: ({ projectId, pageCount, pageNumber, isArchived }) => ({
        url: `/api/Activity/QueryLatestActivityPhotoByPageFilter`,
        method: 'GET',
        params: { projectId, pageCount, pageNumber, isArchived },
      }),
      transformResponse: (response) => response.data.data,
    }),
    QueryActivityByPageFilter: builder.query<
      any,
      QueryActivityByPageFilterParams
    >({
      query: ({ projectId, pageCount, pageNumber, isArchived, keyword }) => ({
        url: `/api/Activity/QueryActivityByPageFilter`,
        method: 'GET',
        params: { projectId, pageCount, pageNumber, isArchived, keyword },
      }),
      transformResponse: (response) => response.data.data,
    }),
    // activity -----------------------------
    CreateActivity: builder.mutation<any, CreateActivity>({
      query: ({
        projectId,
        name,
        totalQty,
        startDate,
        endDate,
        activityUnitId,
      }) => ({
        url: `/api/Activity/CreateActivity`,
        method: 'POST',
        data: {
          projectId,
          name,
          totalQty,
          startDate,
          endDate,
          activityUnitId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateActivity: builder.mutation<any, UpdateActivity>({
      query: ({
        activityId,
        name,
        startDate,
        endDate,
        totalQty,
        activityUnitId,
      }) => ({
        url: `/api/Activity/UpdateActivity`,
        method: 'PUT',
        data: {
          activityId,
          name,
          startDate,
          endDate,
          totalQty,
          activityUnitId,
        },
      }),
    }),
    ArchivedActivity: builder.mutation<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/ArchivedActivity`,
        method: 'PUT',
        data: {
          activityId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    DeleteActivity: builder.mutation<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/DeleteActivity`,
        method: 'DELETE',
        data: {
          activityId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    CreateActivityComment: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `/api/Activity/CreateActivityComment`,
        method: 'POST',
        data,
      }),
    }),
    GetActivityHistoryDetailWithSquash: builder.query<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/GetActivityHistoryDetailWithSquash`,
        method: 'GET',
        params: { activityId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetActivityDetail: builder.query<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/GetActivityDetail`,
        method: 'GET',
        params: { activityId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetActivityRecordsByActivityId: builder.query<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/GetActivityRecordsByActivityId`,
        method: 'GET',
        params: { activityId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.activityId !== previousArg?.activityId;
      },
      keepUnusedDataFor: 0,
      providesTags: ['ActivityRecords'],
    }),
    GetActivityRecordHistoryDetail: builder.query<any, ActivityRecordId>({
      query: ({ activityRecordId }) => ({
        url: `/api/Activity/GetActivityRecordHistoryDetail`,
        method: 'GET',
        params: { activityRecordId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    GetActivityRecord: builder.mutation<any, ActivityRecordId>({
      query: ({ activityRecordId }) => ({
        url: `/api/Activity/GetActivityRecord`,
        method: 'GET',
        params: { activityRecordId },
      }),
      transformResponse: (response) => response.data.data,
    }),
    CreateActivityRecord: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `/api/Activity/CreateActivityRecord`,
        method: 'POST',
        data,
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateActivityRecord: builder.mutation<any, FormData>({
      query: (data) => ({
        url: `/api/Activity/UpdateActivityRecord`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['ActivityRecords'],
    }),
    DeleteActivityRecord: builder.mutation<any, DeleteActivityRecordParams>({
      query: ({ activityRecordId }) => ({
        url: `/api/Activity/DeleteActivityRecord`,
        method: 'DELETE',
        data: {
          activityRecordId,
        },
      }),
    }),
    GetActivityComments: builder.query<ActivityComment[], ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/GetActivityComments`,
        method: 'GET',
        params: { activityId },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.activityId !== previousArg?.activityId;
      },
    }),
    GetActivityProductivity: builder.query<
      ActivityProductivity,
      ActivityProductivityParameters
    >({
      query: ({ activityId, type, year, startDate, endDate, createdBy }) => ({
        url: `/api/Activity/GetActivityProductivity`,
        method: 'GET',
        params: {
          activityId,
          type,
          year,
          startDate,
          endDate,
          createdBy,
        },
      }),
      transformResponse: (response) => response.data.data,
      forceRefetch: ({ currentArg, previousArg }) => {
        return currentArg?.activityId !== previousArg?.activityId;
      },
    }),
    QueryActivityPhotosAsync: builder.query<any, ActivityId>({
      query: ({ activityId }) => ({
        url: `/api/Activity/QueryActivityPhotosAsync`,
        method: 'GET',
        params: { activityId },
      }),
      transformResponse: (response) => response.data.data,
      keepUnusedDataFor: 0,
    }),
    DeleteActivityRecordPhoto: builder.mutation<
      any,
      DeleteActivityRecordPhotoParams
    >({
      query: ({ projectId, activityId, activityRecordId, photoId }) => ({
        url: `/api/Activity/DeleteActivityRecordPhoto`,
        method: 'DELETE',
        data: {
          projectId,
          activityId,
          activityRecordId,
          photoId,
        },
      }),
    }),
    // unit --------------------------
    QueryActivityUnitsByGroupId: builder.query<any, QueryActivityUnits>({
      query: ({ groupId, createdBy }) => ({
        url: `/api/Activity/QueryActivityUnitsByGroupId`,
        method: 'GET',
        params: {
          groupId,
          createdBy,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    CreateActivityUnit: builder.mutation<any, CreateActivityUnit>({
      query: ({ name, groupId }) => ({
        url: `/api/Activity/CreateActivityUnit`,
        method: 'POST',
        data: {
          name,
          groupId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    UpdateActivityUnit: builder.mutation<any, UpdateActivityUnit>({
      query: ({ name, activityUnitId }) => ({
        url: `/api/Activity/UpdateActivityUnit`,
        method: 'PUT',
        data: {
          name,
          activityUnitId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
    DeleteActivityUnit: builder.mutation<any, DeleteActivityUnit>({
      query: ({ activityUnitId }) => ({
        url: `/api/Activity/DeleteActivityUnit`,
        method: 'DELETE',
        data: {
          activityUnitId,
        },
      }),
      transformResponse: (response) => response.data.data,
    }),
  }),
});

export const {
  useGetQueryActivityOptionsByFilterQuery,
  useGetQueryActivityPreviewPhotosByFilterQuery,
  useGetQueryLatestActivityPhotoByFilterQuery,
  useQueryLatestActivityPhotoByPageFilterQuery,
  useQueryActivityByPageFilterQuery,
  // activity --------------------------
  useCreateActivityMutation,
  useUpdateActivityMutation,
  useArchivedActivityMutation,
  useDeleteActivityMutation,
  useGetActivityProductivityQuery,
  useQueryActivityPhotosAsyncQuery,
  useGetActivityDetailQuery,
  useGetActivityHistoryDetailWithSquashQuery,
  // record
  useGetActivityRecordsByActivityIdQuery,
  useGetActivityRecordHistoryDetailQuery,
  // useGetActivityRecordQuery,
  useGetActivityRecordMutation,
  useCreateActivityRecordMutation,
  useUpdateActivityRecordMutation,
  useDeleteActivityRecordMutation,
  useDeleteActivityRecordPhotoMutation,
  // comment ---------------------------
  useCreateActivityCommentMutation,
  useGetActivityCommentsQuery,
  // Unit ------------------------------
  useQueryActivityUnitsByGroupIdQuery,
  useCreateActivityUnitMutation,
  useUpdateActivityUnitMutation,
  useDeleteActivityUnitMutation,
} = activityAPI;
