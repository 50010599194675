import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { DialogPageName } from './interface';
import type { ReportTemplateWithoutData } from './template.interface';

export interface ReportState {
  dialogPageName: DialogPageName;
  reportDialogOpen: boolean;
  requestParams: any;
  selectedTemplate: ReportTemplateWithoutData | null;
}

const initialState: ReportState = {
  dialogPageName: 'empty',
  reportDialogOpen: false,
  requestParams: {},
  selectedTemplate: null,
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setDialogPageName(state, action: PayloadAction<DialogPageName>) {
      state.dialogPageName = action.payload;
    },
    setDialogClose(state) {
      state.reportDialogOpen = false;
    },
    setDialogOpen(state) {
      state.reportDialogOpen = true;
    },
    // Dialog 選完之後，要送給後端的參數
    setRequestParams(state, action: PayloadAction<any>) {
      state.requestParams = action.payload;
    },
    setSelectedTemplate(state, action: PayloadAction<any>) {
      state.selectedTemplate = action.payload;
    },
  },
});

export const {
  setDialogPageName,
  setRequestParams,
  setDialogClose,
  setDialogOpen,
  setSelectedTemplate,
} = reportSlice.actions;
export default reportSlice.reducer;
