import { createSlice } from '@reduxjs/toolkit';

import { newsfeedApi } from './newsfeedAPI';

interface InitialState {
  detail: any;
  newsFeeds: any[];
  weeklyReports: any[];
  
  currentPageCount: number;
  pageCount: number;
  pageNumber: number;
  totalCount: number;
  totalPageCount: number;
}

const initialState: InitialState = {
  detail: {},
  newsFeeds: [],
  weeklyReports: [],

  currentPageCount: 0,
  pageCount: 0,
  pageNumber: 0,
  totalCount: 0,
  totalPageCount: 0,
};

const newsfeedSlice = createSlice({
  name: 'newsfeed',
  initialState,
  reducers: {
    resetState: () => initialState,
    setNoteToStore: (state, action) => {
      const { order, note } = action.payload;
      state.detail.photos[order].notes = note;
    },
    setPhotoNameToStore: (state, action) => {
      const { order, name } = action.payload;
      state.detail.photos[order].photoName = name;
    },
    removePhotoFromStore: (state, action) => {
      const { photoId } = action.payload;
      state.detail.photos = state.detail.photos.filter(
        (v) => v.photoId !== photoId,
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      newsfeedApi.endpoints.GetNewsfeedDetail.matchFulfilled,
      (state, action) => {
        state.detail = action.payload;
      },
    );
    builder.addMatcher(
      newsfeedApi.endpoints.queryNewsfeedAndWeeklyReportWithPaged
        .matchFulfilled,
      (state, action) => {
        const { newsFeeds, weeklyReports } = action.payload;
        if (newsFeeds.pageNumber === 1) {
          state.newsFeeds = newsFeeds.data;
          state.weeklyReports = weeklyReports;
        } else if (newsFeeds.pageNumber > 1) {
          state.newsFeeds = state.newsFeeds.concat(newsFeeds.data);
          state.weeklyReports = state.weeklyReports.concat(weeklyReports);
        }
        state.currentPageCount = newsFeeds.currentPageCount;
        state.pageCount = newsFeeds.pageCount;
        state.pageNumber = newsFeeds.pageNumber;
        state.totalCount = newsFeeds.totalCount;
        state.totalPageCount = newsFeeds.totalPageCount;
      },
    );
  },
});

export const {
  resetState,
  setNoteToStore,
  setPhotoNameToStore,
  removePhotoFromStore,
} = newsfeedSlice.actions;

export default newsfeedSlice.reducer;
