import { createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  getPaymentTypes,
  getPlanTypes,
  getGroupReceiptsByPageFilter,
  postCheckout,
  postPreviewCheckout,
  postCancelSubscription,
  getInvoiceDownloadUrlAsync,
  postPreUpdateSeats,
  postUpdateSeats,
  postPreUpdateNextPlan,
  postUpdateNextPlan
} from './paymentAPI';

export const fetchGetPaymentTypesThunk = createAsyncThunk(
  'payment/fetchPaymentTypes',
  async () => {
    const res = await getPaymentTypes();
    const { data } = res.data;

    return data;
  },
);

export const fetchGetPlanTypesThunk = createAsyncThunk(
  'payment/fetchPlanTypes',
  async () => {
    const res = await getPlanTypes();
    const { data } = await res.data;

    return data;
  },
);

export const fetchGetGroupReceiptsByPageFilterThunk = createAsyncThunk(
  'payment/fetchGroupReceiptsByPageFilter',
  async ({ GroupId, PageNumber, PageCount }, { rejectWithValue }) => {
    try {
      const res = await getGroupReceiptsByPageFilter(
        GroupId,
        PageNumber,
        PageCount,
      );
      const { data } = await res.data;

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPreviewCheckoutThunk = createAsyncThunk(
  'payment/fetchPreviewCheckout',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postPreviewCheckout(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const fetchCheckoutThunk = createAsyncThunk(
  'payment/fetchCheckout',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postCheckout(data);
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchCancelSubscription = createAsyncThunk(
  'payment/fetchCancelSub',
  async (groupId, { rejectWithValue }) => {
    try {
      const res = await postCancelSubscription(groupId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchInvoiceDownloadUrl = createAsyncThunk(
  'getInvoiceDownloadUrlAsync',
  async (groupReceiptId, { rejectWithValue }) => {
    try {
      const res = await getInvoiceDownloadUrlAsync(groupReceiptId);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPreUpdateSeatsThunk = createAsyncThunk(
  'payment/fetchPreUpdateSeats',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postPreUpdateSeats(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const fetchUpdateSeatsThunk = createAsyncThunk(
  'payment/fetchUpdateSeats',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postUpdateSeats(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const fetchPreUpdateNextPlanThunk = createAsyncThunk(
  'payment/fetchPreUpdateNextPlan',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postPreUpdateNextPlan(data);
      return res;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

export const fetchUpdateNextPlanThunk = createAsyncThunk(
  'payment/fetchUpdateNextPlan',
  async (data, { rejectWithValue }) => {
    try {
      const res = await postUpdateNextPlan(data);
      return res;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);