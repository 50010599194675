import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  attendanceRecordTypes: {
    // getAttendanceRecordTypes
    RECORD: 1,
    UNPAIDLEAVE: 2,
    PAIDLEAVE: 3,
  },
  attendanceRecordManagerActionTypes: {
    // getAttendanceRecordManagerActionTypes
    ADDED: 1,
    APPROVED: 2,
    MODIFIED: 3,
    DELETED: 4,
  },
};

const clockSlice = createSlice({
  name: 'clock',
  initialState,
  reducers: {},
});

export default clockSlice.reducer;
