'use client';

import Script from 'next/script';
import gaEvent from '@/utils/ga';
import { useRouter } from 'next/navigation';

import { setPaddleCheckOut } from '@/lib/payment/paymentSlice';
import { useAppDispatch } from '@/lib/hooks';

export default function PaddleLoader() {
  const dispatch = useAppDispatch();
  const router = useRouter();

  return (
    <Script
      strategy="lazyOnload"
      src="https://cdn.paddle.com/paddle/v2/paddle.js"
      onLoad={() => {
        if (process.env.NEXT_PUBLIC_PADDLE_SANDBOX) {
          window.Paddle.Environment.set('sandbox');
        }

        const token = process.env.NEXT_PUBLIC_PADDLE_AUTH_CODE;

        window.Paddle.Initialize({
          token,
          checkout: {
            settings: {
              displayMode: 'inline',
              theme: 'light',
              locale: 'en',
              frameTarget: 'checkout-container',
              frameInitialHeight: '450',
              frameStyle:
                'width: 100%; min-width: 312px; background-color: transparent; border: none;',
            },
          },
          eventCallback(data) {
            if (data.name === 'checkout.completed') {
              const eventPage = window.location.pathname
                .split('/')[1]
                .toLowerCase();
              const groupId = window.location.pathname.split('/')[3];
              const res = data.data;
              gaEvent('payment_click', {
                event_category: 'payment completed',
                event_page: eventPage,
                event_total: res.totals.total,
                event_quantity: res.items.quantity,
              });

              router.push(`/admin/${groupId}/members`);
            }

            if(data.name === 'checkout.loaded'){
              dispatch(setPaddleCheckOut(data));
            }
          },
        });
      }}
    />
  );
}
