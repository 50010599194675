export default function perAmount(planType, paymentType) {
  // 1: Free, 2: Pro, 3: ProPlus
  const prices = {
    1 : 0,
    2 : {1: 25.9, 2: 19.9},
    3 : {1: 20.9, 2: 15.9},
  }

  return prices[planType]?.[paymentType] || 0
}
