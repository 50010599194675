import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../axiosBaseQuery';

export const reportAPI = createApi({
  reducerPath: 'reportAPI',
  baseQuery: axiosBaseQuery(),
  endpoints: () => ({}),
  tagTypes: ['Templates', 'Views'],
});

export default reportAPI;
