import { createSlice } from '@reduxjs/toolkit';
import errorCode from '@/utils/ErrorCode';
import { toast } from 'react-hot-toast';

import {
  fetchGetPaymentTypesThunk,
  fetchGetPlanTypesThunk,
  fetchGetGroupReceiptsByPageFilterThunk,
  fetchCheckoutThunk,
  fetchPreviewCheckoutThunk,
  fetchPreUpdateSeatsThunk,
  fetchInvoiceDownloadUrl,
} from './paymentAction';

const initialState = {
  paymentTypes: {
    data: [],
    error: {},
  },
  planTypes: {
    data: [],
    error: {},
  },
  QueryGroupReceipts: {
    currentPageCount: 0,
    pageNumber: 0,
    pageCount: 0,
    totalCount: 0,
    totalPageCount: 0,
    data: [],
    error: {},
  },
  checkout: {
    data: {},
    error: {},
  },
  preCheckout: {
    data: {},
    error: {},
  },
  paddleCheckout: {
    data: {},
  },
  preUpdateSeats: {
    data: {},
    error: {},
  },
  downloadUrl: '',
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,

  reducers: {
    setPaddleCheckOut: (state, action) => {
      state.paddleCheckout.data = action.payload.data;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchGetPaymentTypesThunk.fulfilled, (state, action) => {
        state.paymentTypes.data = action.payload;
      })
      .addCase(fetchGetPaymentTypesThunk.rejected, (state, action) => {
        state.paymentTypes.error = action.error;
      })
      .addCase(fetchGetPlanTypesThunk.fulfilled, (state, action) => {
        state.planTypes.data = action.payload;
      })
      .addCase(fetchGetPlanTypesThunk.rejected, (state, action) => {
        state.planTypes.error = action.error;
      })
      .addCase(
        fetchGetGroupReceiptsByPageFilterThunk.fulfilled,
        (state, action) => {
          state.QueryGroupReceipts.data = action?.payload?.data;
          state.QueryGroupReceipts.pageNumber = action?.payload?.pageNumber;
          state.QueryGroupReceipts.pageCount = action?.payload?.pageCount;
        },
      )
      .addCase(
        fetchGetGroupReceiptsByPageFilterThunk.rejected,
        (state, action) => {
          // toast.error(errorCode(action.payload.data.errorCode)); // todo  401 不會有資料 errorCode 無法正常運作
          state.QueryGroupReceipts.error = action.error;
        },
      )
      .addCase(fetchCheckoutThunk.fulfilled, (state, action) => {
        state.checkout.data = action.payload;
      })
      .addCase(fetchCheckoutThunk.rejected, (state, action) => {
        toast.error(errorCode(action.payload.data.errorCode));
        state.checkout.error = action.error;
      })
      .addCase(fetchPreviewCheckoutThunk.fulfilled, (state, action) => {
        state.preCheckout.data = action.payload;
      })
      .addCase(fetchPreviewCheckoutThunk.rejected, (state, action) => {
        toast.error(errorCode(action.payload.data.errorCode));
        state.preCheckout.error = action.payload.data;
      })
      .addCase(fetchPreUpdateSeatsThunk.fulfilled, (state, action) => {
        state.preUpdateSeats.data = action.payload.data.data;
      })
      .addCase(fetchPreUpdateSeatsThunk.rejected, (state, action) => {
        toast.error(errorCode(action.payload.data.errorCode));
        state.preUpdateSeats.error = action.payload.data.message;
      })
      .addCase(fetchInvoiceDownloadUrl.fulfilled, (state, action) => {
        state.downloadUrl = action.payload.data.downloadUrl;
      })
      .addCase(fetchInvoiceDownloadUrl.rejected, (state, action) => {
        state.downloadUrl = action.error;
      });
  },
});

export const {
  getPaymentType,
  getPlanTypes,
  getQueryGroupReceipts,
  setPaddleCheckOut,
} = paymentSlice.actions;
export default paymentSlice.reducer;
