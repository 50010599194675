import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCompanyOverView, getCompanies } from './accountAPI';

export const fetchGetCompanyOverViewThunk = createAsyncThunk(
  'account/fetchCompanyOverView',
  async (groupId: number) => {
    const res = await getCompanyOverView(groupId);
    const { value } = res.data.data;
    return value;
  },
);

export const fetchCompaniesThunk = createAsyncThunk(
  'account/fetchCompanies',
  async () => {
    const res = await getCompanies();
    const { data } = res.data;
    return data;
  },
);
