import { createSlice } from '@reduxjs/toolkit';
import { DATE_RANGE } from '@/app/[lang]/app/[groupId]/report/Utils/constant';
import { ReactNode } from 'react';

export type SecondMenuType = 'home' | 'project' | 'todo' | 'report';

type InitialStateType = {
  menu: boolean;
  notification: string;
  drawer: boolean;
  defaultReportPeriod: number;
  showReportTemplate: {
    viewPage: boolean;
    templatePage: boolean;
  };
  isPermissionNotificationVisible: boolean;
  isFlexPaneVisible: boolean;
  flexPane: ReactNode | null;
  isSecondMenuVisible: boolean;
  secondMenu: ReactNode | null;
  secondMenuType: SecondMenuType | null;
};

const initialState: InitialStateType = {
  menu: false,
  notification: 'default',
  drawer: false,
  defaultReportPeriod: 30,
  showReportTemplate: {
    viewPage: true,
    templatePage: true,
  },
  isPermissionNotificationVisible: false,
  isFlexPaneVisible: false,
  flexPane: null,
  isSecondMenuVisible: false,
  secondMenu: null,
  secondMenuType: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setMenuToggle: (state, action) => {
      state.menu = !action.payload;
    },
    setNotificationToggle: (state, action) => {
      state.notification = action.payload;
    },
    setDrawerOpen: (state) => {
      state.drawer = true;
    },
    setDrawerClose: (state) => {
      state.drawer = false;
    },
    setDrawerToggle: (state) => {
      state.drawer = !state.drawer;
    },
    setDefaultReportPeriod: (state, action) => {
      state.defaultReportPeriod = action.payload;
    },
    setShowReportTemplate: (state, action) => {
      state.showReportTemplate = action.payload;
    },
    setPermissionNotificationVisible: (state, action) => {
      state.isPermissionNotificationVisible = action.payload;
    },
    setIsFlexPaneVisible: (state, action) => {
      state.isFlexPaneVisible = action.payload;
    },
    setFlexPane: (state, action) => {
      state.flexPane = action.payload;
    },
    setIsSecondMenuVisible: (state, action) => {
      state.isSecondMenuVisible = action.payload;
    },
    setSecondMenu: (state, action) => {
      state.secondMenu = action.payload;
    },
    setSecondMenuType: (state, action) => {
      state.secondMenuType = action.payload;
    },
  },
});

export const {
  setMenuToggle,
  setDrawerOpen,
  setDrawerClose,
  setDrawerToggle,
  setNotificationToggle,
  setDefaultReportPeriod,
  setShowReportTemplate,
  setPermissionNotificationVisible,
  setIsFlexPaneVisible,
  setFlexPane,
  setIsSecondMenuVisible,
  setSecondMenu,
  setSecondMenuType,
} = appSlice.actions;

export default appSlice.reducer;
