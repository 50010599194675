import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetMembersOverView } from './memberAPI';

export const GetMembersOverViewThunk = createAsyncThunk(
  'AdminConsole/getMembersCount',
  async (id) => {
    const res = await GetMembersOverView(id);
    const { data } = res.data;
    return data;
  },
);

export const fetch = createAsyncThunk();
