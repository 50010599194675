import { configureStore, combineReducers } from '@reduxjs/toolkit';

import userSlice from '@/lib/users/userSlice';
import adminConsoleMemberSlice from '@/lib/adminConsole/member/memberSlice';
import accountSlice from '@/lib/account/accountSlice';
import paymentSlice from '@/lib/payment/paymentSlice';
import dialogsSlice from '@/lib/dialogs/dialogsSlice';
import taskListSlice from '@/lib/taskList/taskListSlice';
import appSlice from '@/lib/app/appSlice';
import groupSlice from '@/lib/group/groupSlice';
import clockSlice from '@/lib/clock/clockSlice';
// RTKQuery
import { groupApi } from '@/lib/group/groupAPI';
import { newsfeedApi } from '@/lib/newsfeed/newsfeedAPI';
import { projectsApi } from '@/lib/projects/projectsAPI';
import { budgetApi } from '@/lib/budget/budgetAPI';
import { taskListApi } from '@/lib/taskList/taskListAPI';
import { usersApi } from '@/lib/users/usersAPI';
import { reportAPI } from '@/lib/report/reportAPI';
import { activityAPI } from '@/lib/activity/activityAPI';
import { notifyMessageApi } from '@/lib/notifyMessage/notifyMessageAPI';
import { scheduleApi } from './schedule/scheduleAPI';
import memberSlice from './member/memberSlice';
import reportSlice from './report/reportSlice';
import timesheetSlice from './timesheet/timesheetSlice';
import equipmentSlice from './equipment/equipmentSlice';
import scheduleSlice from './schedule/scheduleSlice';
import activitySlice from './activity/activitySlice';
import newsfeedSlice from './newsfeed/newsfeedSlice';
import { clockApi } from './clock/clockAPI';
import { equipmentApi } from './equipment/equipmentAPI';
import { memberApi } from './member/memberAPI';
import { timesheetApi } from './timesheet/timesheetAPI';
import { groupSettingApi } from './groupSetting/groupSettingAPI';
import { teamsApi } from './teams/teamsAPI';
import teamsSlice from './teams/teamsSlice';

const store = configureStore({
  reducer: {
    user: userSlice,
    adminConsoleMember: adminConsoleMemberSlice,
    account: accountSlice,
    payment: paymentSlice,
    taskList: taskListSlice,
    app: appSlice,
    group: groupSlice,
    dialogs: dialogsSlice,
    newsfeed: newsfeedSlice,
    activity: activitySlice,
    clock: clockSlice,
    schedule: scheduleSlice,
    equipment: equipmentSlice,
    timesheet: timesheetSlice,
    report: reportSlice,
    member: memberSlice,
    teams: teamsSlice,
    // RTKQuery
    [groupApi.reducerPath]: groupApi.reducer,
    [newsfeedApi.reducerPath]: newsfeedApi.reducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [budgetApi.reducerPath]: budgetApi.reducer,
    [taskListApi.reducerPath]: taskListApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [reportAPI.reducerPath]: reportAPI.reducer,
    [activityAPI.reducerPath]: activityAPI.reducer,
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [notifyMessageApi.reducerPath]: notifyMessageApi.reducer,
    [clockApi.reducerPath]: clockApi.reducer,
    [equipmentApi.reducerPath]: equipmentApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [timesheetApi.reducerPath]: timesheetApi.reducer,
    [groupSettingApi.reducerPath]: groupSettingApi.reducer,
    [teamsApi.reducerPath]: teamsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      groupApi.middleware,
      newsfeedApi.middleware,
      memberApi.middleware,
      equipmentApi.middleware,
      projectsApi.middleware,
      budgetApi.middleware,
      taskListApi.middleware,
      usersApi.middleware,
      reportAPI.middleware,
      activityAPI.middleware,
      scheduleApi.middleware,
      notifyMessageApi.middleware,
      clockApi.middleware,
      equipmentApi.middleware,
      memberApi.middleware,
      timesheetApi.middleware,
      groupSettingApi.middleware,
      teamsApi.middleware,
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
