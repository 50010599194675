'use client';

import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import theme from './theme';

const ThemeProviders = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProviders;
